.button-container {
  width: 100%;
  display: flex;
  justify-content: start;
  margin-top: 10px;
}

.button-main {
  transition: 0.2s;
  background-color: rgb(42, 99, 166);
  color: #ffffff;
  border: 1px solid rgb(42, 99, 166);
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-size: medium;
  font-weight: 500;
}

.button-main:hover {
  transition: 0.2s;
  background-color: rgb(149, 191, 224);
  color: white;
  border: 2px solid rgb(149, 191, 224);
  width: 100%;
  height: 40px;
  font-size: medium;
  font-weight: 500;
}

.button-main:active {
  background-color: #797979;
  color: rgb(255, 255, 255);
  border: 2px solid lightgray;
  width: 100%;
  height: 40px;
  font-size: medium;
  font-weight: 500;
}

.button__buttons_block {
  font-size: large;
  font-weight: 500;
  color: #0065ab;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.button__counter_buttons {
  background-color: lightgray;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button__counter_buttons button {
  font-size: x-large;
  font-weight: 500;
  color: #0065ab;
  width: 20%;
  height: 80%;
  margin-left: 4px;
  margin-right: 4px;
  border: none;
  border-radius: 5px;
  background-color: aliceblue;
}

@media screen and (max-width: 780px) {
  .button-container {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-top: 10px;
  }

  .button-main {
    transition: 0.2s;
    background-color: rgb(42, 99, 166);
    color: #ffffff;
    border: 1px solid rgb(42, 99, 166);
    border-radius: 8px;
    width: 100%;
    height: 60px;
    font-size: medium;
    font-weight: 500;
  }

  .button-main:active {
    background-color: #797979;
    color: rgb(255, 255, 255);
    border: 2px solid lightgray;
    width: 100%;
    height: 60px;
    font-size: medium;
    font-weight: 500;
  }

  .button-main:hover {
    background-color: #0065ab;
    color: rgb(255, 255, 255);
    border: 2px solid lightgray;
    width: 100%;
    height: 60px;
    font-size: medium;
    font-weight: 500;
  }

  .button__buttons_block {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }

  .button__counter_buttons {
    background-color: lightgray;
    width: 100%;
    height: 60px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .button__counter_buttons button {
    width: 20%;
    height: 90%;
    margin-left: 3px;
    margin-right: 3px;
    border: none;
    border-radius: 5px;
    background-color: aliceblue;
  }
}

@media (hover: hover) {
  .button__counter_buttons button:hover {
    width: 20%;
    height: 85%;
    margin-left: 3px;
    margin-right: 3px;
    border: none;
    border-radius: 5px;
    background-color: rgb(99, 156, 206);
    color: white;
  }
}
