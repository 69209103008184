.cart__clear_all {
  transition: 100ms;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(163, 163, 255);
  color: rgb(163, 163, 255);
  border-radius: 8px;
  padding: 5px;
  margin-left: 30px;
}

.cart__clear_all:hover {
  transition: 100ms;
  background-color: rgb(59, 93, 243);
  border: 1px solid rgb(163, 163, 255);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 5px;
  margin-left: 30px;
}

.cart__clear_all:active {
  transition: 100ms;
  background-color: rgb(51, 179, 68);
  border: 1px solid rgb(57, 156, 57);
  color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 5px;
  margin-left: 30px;
}

.cart__page_title {
  width: 83%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout__btn button {
  transition: 0.2s;
  width: 225px;
  height: 40px;
  border: none;
  background-color: rgb(42, 99, 166);
  color: white;
  font-weight: bold;
  font-size: medium;
  border-radius: 10px;
}

.checkout__btn button:hover {
  transition: 0.2s;
  width: 225px;
  height: 40px;
  border: none;
  background-color: rgb(94, 128, 167);
  color: white;
  font-weight: bold;
  font-size: medium;
  border-radius: 10px;
}
.checkout__btn button:active {
  transition: 0.2s;
  width: 225px;
  height: 40px;
  border: none;
  background-color: rgb(172, 154, 154);
  color: white;
  font-weight: bold;
  font-size: medium;
  border-radius: 10px;
}

@media screen and (max-width: 797px) {
  .cart__page_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
