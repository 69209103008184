.good-container {
  display: flex;
  box-shadow: lightgray 3px 3px 10px 0px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  flex-basis: 15%;
  margin: 5px;
  border: 1px solid lightgray;
  padding: 15px 15px 15px 15px;
}

.good-img-block img {
  width: 100%;
}

.goodTitle {
  min-height: 48px;
  width: 100%;
  margin-top: 10px;
  font-size: large;
  font-weight: 600;
}

.goodCaption {
  max-height: 130px;
  margin-top: 10px;
  font-size: small;
  color: rgb(77, 77, 77);
}

.priceLabel {
  color: rgb(61, 152, 255);
  font-size: large;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: end;
  flex-grow: 2;
}

.good__brand_title {
  color: rgb(116, 116, 180);

  margin-top: 10px;
  font-size: large;
  font-weight: 600;
}

.good__lablel_block {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.good__weight {
  font-weight: 500;
  margin-left: 10px;
  color: rgb(153, 153, 153);
  margin-top: 12px;
  height: 100%;
  display: flex;
  align-items: center;
}
