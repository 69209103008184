/* * {
  border: 1px solid red;
} */

.footer__section {
  margin-top: 100px;

  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 70px;
  background-color: rgb(150, 200, 220);
  box-shadow: 0px 3px 10px 0px rgb(64, 141, 230);
}

.footer__container {
  color: rgb(49, 49, 49);
  font-weight: 500;
  font-size: large;
  display: flex;

  align-items: center;
  justify-content: center;
}

.footer__logo {
  width: 120px;
}

.footer__copyright_cont {
  height: 100%;
  display: flex;

  align-content: flex-start;
}

.footer__copyright {
  font-size: xx-small;
  height: 20px;
}

.footer__year_label {
  margin-left: 10px;
  font-size: medium;
}
