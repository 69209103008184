.header-sec {
  box-shadow: lightgray 0px 0px 5px 2px;
  width: 100%;
  height: 60px;
  position: fixed;
  display: flex;
  top: 0;
  background: rgb(42, 99, 166);
  border-bottom: 1px solid lightgray;
}
.logo-block {
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  width: 67%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-img {
  width: 164px;
  filter: invert(1);
}

.menu-table {
  width: 220px;
}

.menu {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.menu a:hover {
  text-decoration: none;
  color: rgb(83, 146, 201);
  font-weight: 600;
}

.menu a:active {
  text-decoration: none;
  color: rgb(52, 103, 145);
  font-weight: 600;
}

.menu-mobile {
  display: none;
  width: 216px;
}

.menu-mobile img {
  width: 20px;
  filter: invert(1);
}

.cart-block {
  width: 216px;
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
}

.cart-img {
  width: 30px;
  filter: invert(1);
}

@media screen and (max-width: 797px) {
  .menu {
    width: 100%;
    display: none;
    justify-content: space-between;
    align-items: center;
  }
  .menu-table {
    width: 0px;
  }

  .menu-mobile {
    display: inline-block;
    position: relative;
    align-items: center;
    width: 216px;
  }

  .menu-mobile img {
    width: 20px;
  }

  .menu_mobile_button {
    background: none;
    border: none;
  }

  .menu_drop_down {
    border-radius: 0 0 5px 5px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    margin: 18px 2px 2px -18px;
    width: 250px;
    height: 250px;
    background-color: rgb(42, 99, 166);
    position: fixed;
  }

  .menu_drop_down a {
    color: rgb(255, 255, 255);
    font-size: large;
    text-decoration: none;
    font-weight: 500;
  }

  .menu_mob_item {
    display: flex;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgb(218, 218, 218);
    width: 84%;
  }
  .menu_mob_item:hover {
    background-color: #69929b;
    color: rgb(255, 255, 255);
    padding: 20px;
    width: 84%;
    height: 100%;
  }

  .menu_mob_item:active {
    background-color: #a1b0b3;
    color: rgb(52, 103, 145);
    padding: 20px;
    width: 84%;
    height: 100%;
  }

  .logo-block {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
