.cart__good-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 5px;
  border-bottom: 1px solid rgb(231, 231, 231);
  width: 100%;
}

.cart__good-img-block img {
  width: 100px;
}

.cart__goodTitle {
  width: 100%;
  margin-top: 10px;
  font-size: large;
  font-weight: 600;
}

.cart__goodCaption {
  max-height: 130px;
  margin-top: 10px;
  font-size: x-small;
}

.cart__priceLabel {
  color: green;
  font-size: x-large;
  font-weight: 500;
  width: 100%;

  display: flex;
  justify-content: end;
  align-items: end;
}
.cart__counter_buttons {
  color: #0065ab;
  font-size: large;
  font-weight: 500;
  background-color: lightgray;
  width: 100%;
  height: 30px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cart__counter_buttons button {
  font-size: large;
  font-weight: 500;
  color: #0065ab;
  width: 30%;
  height: 85%;
  margin-left: 2px;
  margin-right: 2px;
  border: none;
  border-radius: 5px;
  background-color: aliceblue;
}

.cart__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 80%;
}

.cart__section {
  margin-top: 100px;
  display: flex;
  min-height: 72vh;
  align-items: start;
  justify-content: center;
}

.cart__goods_block {
  padding: 5px 20px 5px 5px;
  border-radius: 10px;
  box-shadow: lightgray 3px 3px 10px 0px;
  border: 1px solid lightgray;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cart__buttons_block {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  width: 150px;
}

.cart__caption_block {
  width: 65%;
  display: flex;
  justify-content: space-between;

  flex-direction: column;
}

.cart__page_title h2 {
  color: #5d5a63;
}

.cart__empty_block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart__empty_block p {
  font-size: medium;
  color: gray;
}

.cart__total_block {
  width: 81%;
  height: 100%;
  display: flex;
  justify-content: end;
}

.cart__total_ammounts {
  color: rgb(18, 112, 119);
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: end;
}

@media screen and (max-width: 780px) {
  .cart__good-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin: 5px;
    padding: 10px;
    width: 100%;
  }

  .cart__goods_block {
    width: 100%;
    min-width: 330px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cart__goodTitle {
    width: 100%;
    margin-top: 10px;
    font-size: medium;
    font-weight: 500;
  }

  .cart__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 90%;
  }

  .cart__total_block {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
}
