/* * {
  border: 1px solid red;
} */

.about__container {
  min-height: 72vh;
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}

.about__wrapper {
  width: 85%;
  height: 690px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.about_text__lines {
  width: 95%;
}

.about__text_block_left {
  padding: 10px;
  border: 1px solid rgb(193, 193, 233, 0.5);
  border-radius: 10px;
  color: rgb(46, 46, 46);
  font-size: large;
  font-weight: 400;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.about__text_block_right {
  margin-left: 10px;
  padding: 10px;
  border: 1px solid rgba(193, 193, 233, 0.5);
  border-radius: 10px;
  color: rgb(46, 46, 46);
  font-size: 15px;
  font-weight: 500;
  width: 46%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  min-width: 410px;
}

.centered_h3 {
  text-align: center;
  color: rgb(41 99 166);
}

.gray__line {
  background-color: rgb(230, 239, 255);
}

.bolded {
  font-weight: bold;
}

.leftanchor {
  text-align: left;
}

.requisites_lines_block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.about_map_container {
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 1200px) {
  .about__container {
    min-height: 72vh;
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about__wrapper {
    width: 100%;
    height: auto;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .about_text__lines {
    width: 100%;
  }

  .about__text_block_left {
    padding: 10px;
    border: 1px solid rgb(193, 193, 233, 0.5);
    border-radius: 10px;
    color: rgb(46, 46, 46);
    font-size: large;
    font-weight: 400;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 350px;
  }

  .about__text_block_right {
    margin-left: 0;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid rgb(193, 193, 233, 0.5);
    border-radius: 10px;
    color: rgb(46, 46, 46);
    font-size: 15px;
    font-weight: 500;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: right;
    min-width: 350px;
  }

  .gray__line {
    background-color: rgb(230, 239, 255);
  }

  .bolded {
    font-weight: bold;
  }

  .requisites_lines_block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
