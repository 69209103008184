.goods-section {
  margin-top: 70px;
}

.goods-container {
  max-width: 65%;
  margin: 0 auto;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  justify-content: center;
}

.group-title {
  height: 40px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group-title p {
  font-size: x-large;
  font-weight: 300;
  color: rgb(91, 91, 91);
}

@media screen and (max-width: 1190px) {
  .goods-container {
    max-width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 44% 44%;
    justify-content: center;
  }
}

@media screen and (max-width: 780px) {
  .goods-container {
    max-width: 97%;
    min-width: 360px;
    margin: 0 auto;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 97%;
    justify-content: center;
  }

  .group-title p {
    font-size: x-large;
    width: 80%;
    font-weight: 300;
    color: rgb(91, 91, 91);
  }
}
